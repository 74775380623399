<template>
   <div id="box">
      <!-- 单独的量表评估报告 -->
      <h1>光照治疗报告</h1>
      <h2>重庆市精神卫生中心</h2>
      <table v-if="this.update.cname">
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center"
                class="inputDeep">
               姓名：{{ this.update.cname }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               就诊卡号：{{ this.update.cid }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               科室：{{ update.department }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               指导医生：{{ update.doctor }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="10"
                style="text-align: center">
               报告时间：{{ this.update.dateTime.replace("T", "-") }}
            </td>
         </tr>
         <tr>
            <td rowspan="1"
                colspan="10"
                id="jieshao"
                valign="top"
                style="font-size: 18px;">
               报告简介：在光照治疗过程中，模拟太阳光谱的自然光线进入人体眼睛中，会被视网膜上的第三种感光细胞感受到，释放神经递质，参与调节人体血清素、去甲肾上腺素以及褪黑激素等的分泌，从而影响生物节律，改善情绪、睡眠、认知和行为。<br />
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="1"
                style="text-align: center">
               序号
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               报告类型
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               光照强度
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               治疗时长
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               日期
            </td>
         </tr>
         <template>
            <tr>
               <td valign="center"
                   rowspan="1"
                   colspan="1"
                   style="text-align: center;">
                  1
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ this.update.rtName }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ this.update.data.split(",")[1] + "lux" }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ this.update.data.split(",")[0] + "min" }}
               </td>
               <td valign="center"
                   rowspan="1"
                   colspan="2"
                   style="text-align: center">
                  {{ this.update.dateTime.replace("T", "-") }}
               </td>
            </tr>
         </template>
      </table>

      <table id="table2">
         <div style="text-align: left">
            <p>测验结论：{{update.review}}</p>
         </div>
      </table>
      <table id="table3">
         <tr style="height: 40px">
            <td valign="left"
                rowspan="1"
                colspan="4"
                style="text-align:rigth; border: none">
               _______年_______月_______日填
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: right; border: none">
               签字：________<br />
            </td>
         </tr>
      </table>

      <p style="text-align: left">当前时间：{{ this.updateTime }}</p>
   </div>
</template>

<script>
import axios from "../../http/axios";

export default {
   name: "Dashboard",
   data () {
      return {
         update: [],
         time: "",
         CID: "",
         updateTime: "",
      };
   },
   created () {
      this.search();
   },
   updated () { },
   mounted () {
      this.addDate();
   },

   computed: {
      cid () {
         return this.$route.params.cid;
      },
      id () {
         return this.$route.params.id;
      },
   },
   methods: {
      // 通过关键字查询患者
      async search () {
         const data = await axios({
            url: `/Report/find?&findKey=${this.cid}&_id=${this.id}`,
            method: "get",
         });
         if (data.data.msg == "请求处理正常") {
            this.update = data.data.data[0];
         }
      },
      //获取当前年月日
      addDate () {
         const nowDate = new Date();
         const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            date: nowDate.getDate(),
         };
         const newmonth = date.month > 10 ? date.month : "0" + date.month;
         const day = date.date > 10 ? date.date : "0" + date.date;
         this.updateTime = date.year + "-" + newmonth + "-" + day;
      },
   },
};
</script>

<style lang="scss" scoped>
#box {
   width: 800px;
   margin: auto;
}
h1 {
   color: black;
}
h2 {
   color: black;
}
table {
   border: 1px solid black;
   border-collapse: collapse;
   margin: 0 auto;
   width: 800px;
}
table td,
th {
   border: 1px black solid;
   background-color: white;
   height: 40px;
   width: 100px;
   color: black;
   //  font-weight: 500;
}
#table2 {
   width: 798px;
   height: 200px;
   border-top: none;
   div {
      width: 798px;
      height: 200px;
      p {
         // padding: 0;
         margin: 0px;
         padding: 20px 20px;
         color: black;
      }
   }
}
#table3 {
   border-top: none;
   color: black;
}

#jieshao {
   // line-height: 0px;
   word-wrap: break-word;
   word-break: break-all;
   padding: 0;
   width: 800px;
   text-align: left;
   height: 200px;
   color: black;
}
</style>
